import React, { Component } from 'react';

export class JSMEeditor extends Component {

  // Lifecycle Methods:
  componentDidMount() {
    // setTimeout(this.loadJSME,10)
  }

  // Functions:
  loadJSME() {
    window.loadJSME("jsme_container")
  }

  // Render:
  render() {
    return(
      <div id='jsme_container'></div>
    );
  }
}
