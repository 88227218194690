import React, { Component } from 'react';
import { Metpred } from './Metpred';
import { CpLogD } from './CpLogD';
import { Prosilico } from './Prosilico';
import { Ptp } from './Ptp'

export class ResultBox extends Component {
  // Render:
  render() {
    let render = "";

    switch (this.props.model) {
      case "Metpred":
        render = <Metpred smiles={this.props.smiles} molfile={this.props.molfile} />;
        break;
      case "cpLogD":
        render = <CpLogD smiles={this.props.smiles} molfile={this.props.molfile} />;
        break;
      case "PTP":
        render = <div>
        <p className="subtitle is-4">Predictive Target Profile</p>
        <Ptp smiles={this.props.smiles} molfile={this.props.molfile} /></div>;
        break;
      case "Prosilico":
        render = <Prosilico smiles={this.props.smiles} />;
        break;
      default:
        render = this.props.model + " model not yet implemented.";
    }

    return (
      <div className="container notification">
        <p className="title">{this.props.model}</p>
        {render}
      </div>
    );
  }
}
