import React, { Component } from 'react';
import pharmbio_logo from './images/pharmbio_logo_new.png';
import uu_logo from './images/uu_logo.png'


export class Header extends Component {
  render() {

    return (

      <nav id="headbar" className="navbar is-white is-fixed-top" style={{display: "flex", float:"left",width:"100%", paddingLeft:"20px", paddingRight:"20px"}}>
  <div className="navbar-brand" style={{display: "flex"}}>
    <a className="navbar-item">
      <img src={pharmbio_logo} style={{display: "flex", height: "36px", margin: "auto", maxHeight:"36px"}} />
    </a>
  </div>
    <div className="navbar-start" style={{display: "flex"}}>
      <h1 className="title" style={{margin: "auto", marginLeft: "10px"}}>
      PredGUI
      </h1>
      <h1 className="title" style={{margin: "auto", marginLeft: "10px", fontSize:"20px", fontWeight:"700"}}>
      - Predictive Graphic User Interface
      </h1>

    </div>

    <div className="navbar-brand">

        <a className="navbar-item">
          <img src={uu_logo} style={{height: "64px", marginTop: "auto", marginBottom: "auto", maxHeight:"64px"}} />
        </a>

  </div>


</nav>


    );
  }
}
