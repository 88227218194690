import React, { Component } from 'react';


export class Footer extends Component {
  render() {
    return (


      <footer className="is-fixed-bottom" style={{position: "absolute",
 
 width: "100%",
 paddingTop: "50px",
  paddingBottom:"10px",
}}>
          <div className="content has-text-right">
              <p style={{fontSize:"12px"}}>
                Developed by <strong>Rikard Nyström</strong>  and <strong>Roberto Melargo</strong>
              </p>
          </div>
      </footer>
    )


  }
}
