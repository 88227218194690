import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import logo from './loading.gif';
import ProgressiveImage from 'react-progressive-image';
import { Dropdown } from 'react-bulma-components/full';

export class Prosilico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      smiles: undefined,
      confidence: 0.7,
      urlConfidence: 0.7,
      largeVisualisation: false,
      visualising: 'fa',
    };
  }

  // Lifecycle Methods:
  componentWillMount() {
    this.setState({ smiles: this.props.smiles });
  }
  componentDidMount() {
    let timeout = 1000;
    setInterval(this.compareConfidence.bind(this), timeout);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.smiles !== this.state.smiles) { //Avoids requesting new prediction stats when re-rendering other components...
      this.setState({ smiles: nextProps.smiles, loading: true });
      this.getProsilicoData(nextProps.smiles, this.state.urlConfidence);
    }
  }



  // Functions:
  async getProsilicoData(smiles, urlConfidence) {
    const cleanSMILES = decodeURI(smiles.split("%0A")[0]);
    const url = '/prosilico?smiles=' + encodeURIComponent(cleanSMILES.split("%0A")[0]) + '&confidence=' + urlConfidence;
    //console.log("in getProsilicoData:");
    //console.log(cleanSMILES.split("%0A")[0]);
    //console.log(encodeURIComponent(cleanSMILES.split("%0A")[0]));
    const response = await fetch(url);
    const body = await response.json();
    this.setState({ data: body, loading: false });
  }
  handleConfidenceChange(e) {
    this.setState({ confidence: e.target.value });
  }
  compareConfidence() {
    let confidence = this.state.confidence;
    let urlConfidence = this.state.urlConfidence;
    let smiles = this.state.smiles;

    if ( confidence !== urlConfidence ) {
      this.setState({ urlConfidence: confidence, loading:true });
      if ( smiles === "" ) {
        console.log("No molecule submitted.");
      } else {
        this.getProsilicoData(smiles, urlConfidence);
      }
    }
  }
  changeVisualising(visualising) {
    this.setState({ visualising });
  }
  handleImageErrored() {
    this.setState({ loading: "failed to load" });
  }
  toggleLargeVisualisation() {
    let current = this.state.largeVisualisation;
    this.setState({ largeVisualisation: !current });
  }


  // Render:
  render() {

    // VISUALISATION:
    let url = "";
    let visualisationSmiles = "&smiles=" + encodeURIComponent(this.state.smiles);
    // conf = 0.7 -> let visualisationConfidence = "&confidence=" + encodeURIComponent(this.state.urlConfidence);
    if ( this.state.data["fasol"] ) { // Testing for undefined
      url = this.state.data[this.state.visualising].url.match(/(.*)\/v1\/.*/)[1] + "/v1/predictImage?imageWidth=250&imageHeight=320";
    }

    let visualisation = (
      <div className="tile is-child box">
        <p className="subtitle has-text-centered">Visualisation</p>
        <Dropdown value={this.state.visualising} onChange={this.changeVisualising.bind(this)} {...this.props}>
          <Dropdown.Item value="fa">fabs</Dropdown.Item>
          <Dropdown.Item value="fasol">fdiss</Dropdown.Item>
          <Dropdown.Item value="fu">fu</Dropdown.Item>
          <Dropdown.Item value="Vss">Vss</Dropdown.Item>
          <Dropdown.Item value="CLint">CLint</Dropdown.Item>
        </Dropdown>
        <figure className="image" data-tip data-for='Visualisation'>
          {(() => {
            if(this.state.smiles && this.state.smiles.split("JME")[0] !== "%0A") {  // PRevents drawing visual if there are no smiles drawn.
              return (
                <a onClick={this.toggleLargeVisualisation.bind(this)}>
                  <ProgressiveImage src={url + visualisationSmiles} placeholder={logo} onError={this.handleImageErrored.bind(this)}>
                   {(src, loading) => (
                    <img style={{ height: loading ? "128px" : "100%", width: loading ? "128px" : "100%", margin: "auto"}} src={src} alt="molecule"/>
                    )}
                  </ProgressiveImage>
                </a>
              )
            }
          })()} {/*Immediate function call*/}
        </figure>
        <ReactTooltip id='Visualisation'>
          <div className="content" style={{ textAlign: 'left', width: '400px' }} >
            <p>Visualisation</p>
            <p>Molecular regions that contribute most to the
            parameter of interest are marked with colors of
            different intensity (red for positive contribution and
            blue for negative contribution; higher color intensity
            or more significant contribution).</p>
          </div>
        </ReactTooltip>
      </div>
    );


    // STATISTICS:
    let stats = <div className="tile is-child box"></div>

    if (this.state.smiles.split("JME")[0] === "%0A" || this.state.smiles === "") {
      stats = (
        <div className="tile is-child box">
          <p className="title">Draw a molecule</p>
          Draw a molecule to start making predictions.
        </div>
      );
    } else if (this.state.loading) {
      stats = (
        <div className="tile is-child box">
          <figure className="image">
            <img style={{ height: "128px", width: "128px", margin: "auto" }} src={logo} alt="Loading..."/>
          </figure>
        </div>
      );
    } else if (this.state.data["fasol"]){
      stats = (
      <div className="tile is-child box">
        <div className="tile is-parent">
          <div className="tile is-child box">
            <p className="title has-text-centered">Absorption</p>
            <table className="table is-fullwidth is-hoverable">
              <thead><tr><td></td><td></td></tr></thead>
              <tbody>
                <tr data-tip data-for='fa'>
                  <th style={{ textAlign: 'right' }}> fabs </th>
                  <td> {Math.round(this.state.data.fa.mid*100) + "% (" +
                        Math.round(this.state.data.fa.lower*100) + "%, " +
                        Math.round(this.state.data.fa.upper*100) + "%)"} </td>
                </tr>
                <tr data-tip data-for='fasol'>
                  <th style={{ textAlign: 'right' }}> fdiss </th>
                  <td>
                    { Math.round(this.state.data.fasol.mid*100) + "% (" +
                      Math.round(this.state.data.fasol.lower*100) + "%, " +
                      Math.round(this.state.data.fasol.upper*100) + "%)"} </td>
                </tr>
              </tbody>
              <tfoot><tr><td></td><td></td></tr></tfoot>
            </table>

            <ReactTooltip id='fa'>
              <div className="content" style={{ textAlign: 'left', width: '400px' }} >
                <p>Fraction absorbed (fabs)</p>
                <p>Predicts passive intestinal permeability-based fraction absorbed in-vivo in man
                (not considering solubility, active transport or instability in GI fluids).</p>
                <p>fabs-range: 0 % to 100 %</p>
                <p>Accuracy forward-looking predictions: Q2 = 0.8; median error = 5%</p>
              </div>
            </ReactTooltip>
            <ReactTooltip id='fasol'>
              <div className="content" style={{ textAlign: 'left', width: '400px' }} >
                <p>Maximum in-vivo solubility/dissolution potential (fdiss)</p>
                <p>Predicts the maximum solubility/dissolution potential in the human gastrointestinal tract in-vivo following oral dosing. A value of 40 % shows that maximally 40 % of an oral dose is anticipated to be dissolved using an optimal oral formulation. A value of 100 % indicates complete in-vivo solubility/dissolution potential with an optimized oral formulation.</p>
                <p>fdiss-range: 0 % to 100 %</p>
                <p>Accuracy forward-looking predictions: Q2=0.5; median error &lt; 10%</p>
              </div>
            </ReactTooltip>
          </div>

          <div className="tile is-child box">
            <p className="title has-text-centered">Distribution</p>
            <table className="table is-fullwidth is-hoverable">
              <thead><tr><td></td><td></td></tr></thead>
              <tbody>
                <tr data-tip data-for='Fu'>
                  <th style={{ textAlign: 'right' }}> fu </th>
                  <td>
                    { (this.state.data.fu.mid*100).toPrecision(3) + "% (" +
                      (this.state.data.fu.lower*100).toPrecision(3) + "%, " +
                      (this.state.data.fu.upper*100).toPrecision(3) + "%)"} </td>
                </tr>
                <tr data-tip data-for='Vss'>
                  <th style={{ textAlign: 'right' }}> Vss </th>
                  <td>
                    { this.state.data.Vss.mid.toPrecision(3) + " (" +
                      this.state.data.Vss.mid.toPrecision(3) + ", " +
                      this.state.data.Vss.mid.toPrecision(3) + ") L/kg"} </td>
                </tr>
              </tbody>
              <tfoot><tr><td></td><td></td></tr></tfoot>
            </table>

            <ReactTooltip id='Fu'>
              <div className="content" style={{ textAlign: 'left', width: '400px' }} >
                <p>Fraction unbound in human plasma (fu)</p>
                <p>Predicts in-vitro fraction unbound in human plasma.</p>
                <p>fu-range: 0.02 % to 100 %</p>
                <p>Accuracy forward-looking predictions: Q2 = 0.7 for log fu; median error &lt; 2-fold</p>
              </div>
            </ReactTooltip>
            <ReactTooltip id='Vss'>
              <div className="content" style={{ textAlign: 'left', width: '400px' }} >
                <p>Steady-state volume of distribution (Vss)</p>
                <p>Predicts steady-state volume of distribution in-vivo in man (not considering enterohepatic circulation).</p>
                <p>Vss-range: 0.07 to 140 L/kg</p>
                <p>Accuracy forward-looking predictions: Q2 = 0.5 for log Vss; median error &lt; 2-fold </p>
              </div>
            </ReactTooltip>
          </div>

          <div className="tile is-child box">
            <p className="title has-text-centered">Metabolism</p>
            <table className="table is-fullwidth is-hoverable">
              <thead><tr><td></td><td></td></tr></thead>
              <tbody>
                <tr data-tip data-for='CLint'>
                  <th style={{ textAlign: 'right' }}> CLint </th>
                  <td>
                      { Math.round(this.state.data.CLint.mid) + " (" +
                        Math.round(this.state.data.CLint.lower) + ", " +
                        Math.round(this.state.data.CLint.upper) + ") mL/min"} </td>
                </tr>
              </tbody>
              <tfoot><tr><td></td><td></td></tr></tfoot>
            </table>
            <ReactTooltip id='CLint'>
              <div className="content" style={{ textAlign: 'left', width: '500px' }} >
                <p>Intrinsic hepatic metabolic clearance (CLint)</p>
                <p>Predicts intrinsic hepatic metabolic clearance in-vivo in man (phase I metabolism and conjugation).</p>
                <p>Vss-range: 5 to 75000 mL/min*</p>
                <p>*LOQ for in-vitro hepatocyte assay typically corresponds to ca: 1000 mL/min.</p>
                <p>Accuracy forward-looking predictions: Q2 = 0.5 for log CLint; median error = 3-fold</p>
              </div>
            </ReactTooltip>
          </div>
        </div>

        <div className="tile is-parent">

          <div className="tile is-child is-6 box">
            <p className="title has-text-centered">Secondary Parameters</p>
            <table className="table is-fullwidth is-hoverable">
              <thead><tr><td></td><td></td></tr></thead>
              <tbody>
                <tr data-tip data-for='CLH'>
                  <th style={{ textAlign: 'right' }}> CLH </th>
                  <td> {this.state.data.CLH.toPrecision(3)} mL/min </td>
                </tr>
                <tr data-tip data-for='t2'>
                  <th style={{ textAlign: 'right' }}> t½ </th>
                  <td>{Math.round(this.state.data.t2)} h
                    { this.state.data.t2 < 2 ?
                        <span className="tag is-white" style={{ float: 'right' }}>Short Half-life</span>
                        : this.state.data.t2 > 2 && this.state.data.t2 < 8 ?
                        <span className="tag is-light" style={{ float: 'right' }}>Medium Half-life</span>
                        : this.state.data.t2 > 8 ?
                        <span className="tag is-dark" style={{ float: 'right' }}>Long Half-life</span>
                        : <span></span> }
                  </td>
                </tr>
                <tr data-tip data-for='F'>
                  <th style={{ textAlign: 'right' }}> F </th>
                  <td>{Math.round(this.state.data.F*100)} % &nbsp;
                  { this.state.data.F < 0.15 ?
                      <span className="tag is-danger" style={{ float: 'right' }}>Low Bioavailability</span>
                      : this.state.data.F > 0.15 && this.state.data.F < 0.5 ?
                      <span className="tag is-warning" style={{ float: 'right' }}>Medium Bioavailability</span>
                      : this.state.data.F > 0.5 ?
                      <span className="tag is-success" style={{ float: 'right' }}>High Bioavailability</span>
                      : <span></span> }
                  </td>
                </tr>
              </tbody>
              <tfoot><tr><td></td><td></td></tr></tfoot>
            </table>

            <ReactTooltip id='CLH'>
              <div className="content" style={{ textAlign: 'left', width: '500px' }}>
                <p>Hepatic clearance (CLH)</p>
                <p>Calculated based on predicted CLint and fu, hepatic blood flow (1500 mL/min) and the well-stirred liver model.</p>
              </div>
            </ReactTooltip>
            <ReactTooltip id='t2'>
              <div className="content" style={{ textAlign: 'left', width: '500px' }} >
                <p>Half-life (t½)</p>
                <p>Calculated based on predicted CLH and Vss (not considering excretion or enterohepatic circulation).</p>
                <p>Short (&lt;2 h), medium (2-8 h), long (&gt;8 h).</p>
                <p>The median prediction error of t½, when also
                considering excretion, is &lt;3-fold in both
                internal cross-validations and external blind
                validations by international pharmaceutical
                companies.</p>
              </div>
            </ReactTooltip>
            <ReactTooltip id='F'>
              <div className="content" style={{ textAlign: 'left', width: '500px' }} >
                <p>Oral bioavailability (F)</p>
                <p>Calculated based on predicted fabs (not considering active transport or gut-wall metabolism), fdiss and CLH.</p>
                <p>Low (&lt;15 %), medium (15-50 %), high (&gt;50 %).</p>
                <p>The median prediction error, when also considering active efflux and gut-wall metabolism, is 1.25-fold (or 0.15 units).</p>
              </div>
            </ReactTooltip>
          </div>

          <div className="tile is-child box">
            <p className="title has-text-centered">Indications</p>
            <table className="table is-fullwidth is-hoverable">
              <thead><tr><td></td></tr></thead>
              <tbody>
                <tr data-tip data-for='Indications'>
                  <td>
                    { this.state.data.CLint.lower<5000 && this.state.data.fa.lower < 0.95 ?
                      <div className="has-text-danger">
                        <FontAwesomeIcon icon="exclamation-triangle"/>&nbsp;Renal and/or biliary clearance could contribute significantly to total clearance and produce a lower t½ than estimated based on hepatic clearance only.
                      </div>
                    : <div></div> }
                    { this.state.data.fa.lower<0.9 ?
                      <div className="has-text-danger">
                          <FontAwesomeIcon icon="exclamation-triangle"/>&nbsp;Potential for significant influence of efflux on fabs and F (if efflux occurs)
                      </div>
                    : <div></div> }
                    { this.state.data.CLint.lower<5000 && this.state.data.fa.lower < 0.95 ?
                      <div>
                          Contact Prosilico for additional prediction of renal and biliary excretion.
                      </div>
                    : <div></div> }
                    { this.state.data.fa.lower<0.9 ?
                      <div>
                          Contact Prosilico for additional predictions of efflux (MDR1, BCRP, MRP2) and its role.
                      </div>
                    : <div></div> }
                  </td>
                </tr>
              </tbody>
            </table>
            <ReactTooltip id='Indications'>
              <div className="content" style={{ textAlign: 'left', width: '500px' }} >
                <p>Indications</p>
                <p>In cases when the system indicates that
                excretion and/or efflux could be involved and
                significant, or if the platform is unable to
                predict the estimates for your compound(s), contact
                Prosilico for additional predictions and
                guidance.</p>
              </div>
            </ReactTooltip>
          </div>
        </div>
      </div>
      );
    }



    // MODAL:
    const Modal = ({children, closeModal, modal, url, smiles}) => {
      if (!modal) {
          return null;
      }
      return (
      <div id="modal" className="modal is-active">
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-content has-text-centered">
          <ProgressiveImage src={url + "&smiles=" + encodeURIComponent(smiles)} placeholder={logo}>
            {(src, loading) => (
              <img style={{ height: loading ? "256px" : "100%", width: loading ? "256px" : "100%", margin: "auto" }} src={src} alt="molecule"/>
            )}
          </ProgressiveImage>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
      </div>
      );
    }

    if ( this.state.data[this.state.visualising] !== undefined ) {
        url = this.state.data[this.state.visualising+''].url.match(/(.*)\/v1\/.*/)[1] + "/v1/predictImage?imageWidth=600&imageHeight=600";
    }

    let modal = (
      <Modal
        closeModal={this.toggleLargeVisualisation.bind(this)}
        modal={this.state.largeVisualisation}
        url={url}
        smiles={this.state.smiles}
      ></Modal>
    )



    // FINAL CONTENT:
    let content = (
      <div className="tile is-ancestor">
        <div className="tile is-parent is-4 is-vertical">   {/*Controls sizing between visual and statistics*/}
          {visualisation}
        </div>
        <div className="tile is-parent">
          {stats}
        </div>
        {modal}
      </div>
    )

    return content;
  }
}
