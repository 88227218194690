import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';
import logo from './loading.gif';

export class CpLogD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: undefined,
      smiles: undefined,
      confidence: 0.7,
      urlConfidence: 0.7,
      molfile: undefined
    };
  }

  // Lifecycle Methods:
  componentWillMount() {
    this.setState({ smiles: this.props.smiles });
    this.setState({ molfile: this.props.molfile });
  }
  componentDidMount() {
    let timeout = 1000;
    setInterval(this.compareConfidence.bind(this), timeout);

    if(window.DEBUG) console.log("Mounting CpLogD");
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ smiles: nextProps.smiles, loading: true });
    this.setState({ molfile: nextProps.molfile, loading: true });
  }


  // Functions:
  handleConfidenceChange(e) {
    this.setState({ confidence: e.target.value });
  }
  compareConfidence() {
    let confidence = this.state.confidence;
    let urlConfidence = this.state.urlConfidence;

    if ( confidence !== urlConfidence ) {
      this.setState({ urlConfidence: confidence });
      if (window.DEBUG) console.log("New confidence: " + confidence);
    }
  }
  handleImageErrored() {
    this.setState({ loading: "failed to load" });
  }

  // Render:
  render() {
    let visualisationSmiles = "&molecule=" + this.state.molfile;
    visualisationSmiles = visualisationSmiles.replace("#","%23")
    let visualisationConfidence = "&confidence=" + this.state.urlConfidence;
    let url = "https://cplogd.serve.scilifelab.se/api/v2/predictImage?imageWidth=900&imageHeight=473&addPrediction=true";
    console.log(visualisationSmiles)
    if (window.DEBUG) console.log("Getting image from " + url + visualisationSmiles + visualisationConfidence);

    let confidence = (
      <div className="box" style={{height: "149px"}}>
        <p className="subtitle has-text-centered">Confidence</p>
        <input
          className="slider is-fullwidth is-link"
          type="range"
          step="0.01"
          min="0"
          max="1"
          value={this.state.confidence}
          onChange={this.handleConfidenceChange.bind(this)}
        />
        <input
          className="input"
          value={this.state.confidence}
          onChange={this.handleConfidenceChange.bind(this)}
        />
      </div>
    )

    let image = (
      <div className="tile is-child">
        <figure className="image is-3by2">
          <ProgressiveImage src={url + visualisationConfidence + visualisationSmiles} placeholder={logo} onError={this.handleImageErrored.bind(this)}>
            {(src, loading) => (
              <img style={{ height: loading ? "128px" : "100%", width: loading ? "128px" : "100%", margin: "auto" }} src={src} alt="molecule"/>
            )}
          </ProgressiveImage>
        </figure>
      </div>
    );

    return (
      <div className="tile is-ancestor">
        <div className="tile is-3 is-vertical is-parent">
          <div className="tile is-child">
            {confidence}
          </div>
        </div>
        <div className="tile is-parent">
          <div className="tile is-child box">
            {image}
          </div>
        </div>
      </div>
    );
  }
}
