import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';
import logo from './loading.gif';

export class Metpred extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: undefined,
      smiles: undefined,
      molfile: undefined
    };
  }

  // Lifecycle Methods:
  componentWillMount() {
    this.setState({ smiles: this.props.smiles });
    this.setState({ molfile: this.props.molfile });
  }
  componentDidMount() {
    let smiles = this.state.molfile;
    this.getPredictionStats(smiles);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.smiles !== this.state.smiles) { //Avoids requesting  new prediction stats when rerendering other components...
      this.setState({ smiles: nextProps.smiles, loading: true });
      this.setState({ molfile: nextProps.molfile, loading: true });
      this.getPredictionStats(nextProps.molfile);
    }
  }

  // Functions:
  async getPredictionStats(smiles) {
    const url = '/metpred?predictionType=predictionHR&smiles=';
    const response = await fetch(url + smiles.replace("+","%2b"));
    const body = await response.text();
    this.setState({ data: body, loading: false });
  }
  handleImageErrored() {
    console.log("Failed to load!")
  }

  // Render:
  render() {
    let smiles = "?compound=" + this.props.molfile.replace("+","%2b");
    let url = "https://metpred.serve.scilifelab.se/v2/predictionImage";

    let image = (
      <figure className="image is-3by2">
        <ProgressiveImage src={url + smiles} placeholder={logo} onError={this.handleImageErrored.bind(this)}>
          {(src, loading) => (
            <img style={{ height: loading ? "128px" : "100%", width: loading ? "128px" : "100%", margin: "auto" }} src={src} alt="molecule"/>
          )}
        </ProgressiveImage>
      </figure>
    );

    let textareaStyle = this.state.loading ? " is-loading" : ""; //Spinning icon in top-right of textarea

    let text = (
      <div className={"control is-large" + textareaStyle}>
        <textarea
          className="textarea is-size-5"
          type="text"
          rows="20"
          readOnly
          value={this.state.loading ? "Loading..." : this.state.data}
        ></textarea>
      </div>
    )

    return (
      <div className="tile is-ancestor">
        <div className="tile is-parent is-8">
          <div className="tile is-child box">
            {image}
          </div>
        </div>

        <div className="tile is-parent">
          <div className="tile is-child">
            {text}
          </div>
        </div>
      </div>
    );
  }
}
