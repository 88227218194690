import React, { Component } from 'react';
import { MenuBox } from './MenuBox';
import { ResultList } from './ResultList';
import { Header } from './Header';
import 'bulma/css/bulma.css';
import 'bulma-extensions/bulma-slider/dist/css/bulma-slider.min.css';
import { Footer } from './Footer'

//Insert Footer

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      smiles: "",
      molfile: "",
      models: {
        Metpred: false,
        cpLogD: false,
        PTP: false,
        //Prosilico: false
      }
    }
  }

  // Lifecycle methods:
  componentDidMount() {
    let timeout = 1000;
    setInterval(this.compareSmiles.bind(this), timeout);
  }

  // Functions:
  compareSmiles() {
    let currentSmiles = this.state.smiles;
    let nextSmiles = encodeURI(document.JME.smiles());
    let molFile = encodeURI(document.JME.molFile())
    // console.log(molFile)
    if ( nextSmiles !== currentSmiles ) {
      this.setState({ molfile: molFile });
      this.setState({ smiles: nextSmiles });
    }
  }

  changeModels(e) {
    let m = e.target.dataset.message;
    let modelsCopy = this.state.models;

    modelsCopy[m] = !modelsCopy[m];
    this.setState({ models: modelsCopy });
  }

  // Render:
  render() {
    return (
      <div>
        <Header />
        <MenuBox models={this.state.models} changeModels={this.changeModels.bind(this)} />
        <ResultList models={this.state.models} molfile={this.state.molfile} smiles={this.state.smiles} />
        <Footer />
      </div>
    )
  }
}
